import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';
import { useHSFormsScriptLoaded } from '../../../helpers/hooks';

const StyledThumbnail = styled.img`
  max-width: 614px;
  max-height: 464px;
  order: 1;
  object-fit: cover;

  @media (max-width: 992px) {
    max-width: 400px;
    max-height: 300px;
  }

  @media (max-width: 768px) {
    max-width: 300px;
    max-height: 200px;
    order: 2;
  }

  @media (max-width: 576px) {
    max-height: 210px;
  }
`;

const WebinarPageForm = styled.div`
  margin-left: 15px;
  order: 2;
  border-radius: 16px;
  border: 1px solid var(--neutral-gray-200-strokes, #d0d0d0);
  background: var(--shade-white-0, #fff);
  padding: 20px 20px 20px 20px;
  min-height: fit-content;
  @media (max-width: 576px) {
    margin-left: 0;
  }

  @media (max-width: 768px) {
    order: 1;
    margin-bottom: 20px;
    max-width: 320px;
  }
`;

const Title = styled.h1`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -1px;
  color: #00a5c8;
  margin-bottom: 15px;

  @media (max-width: 992px) {
    font-size: 48px;
    line-height: 56px;
  }

  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 42px;
  }

  @media (max-width: 576px) {
    font-size: 24px;
    line-height: 28px;
  }
`;

const StyledVideo = styled.iframe`
  width: 900px;
  height: 520px;
  order: 1;

  @media (max-width: 992px) {
    width: 700px;
    height: 400px;
  }

  @media (max-width: 768px) {
    width: 500px;
    height: 300px;
    order: 2;
  }

  @media (max-width: 576px) {
    width: 300px;
    height: 180px;
  }
`;

const ThankYouText = styled.div`
  margin-left: 15px;
  max-width: 430px;
  max-height: 196px;
  align-self: flex-start;
  order: 2;

  @media (max-width: 576px) {
    margin-left: 0;
    max-width: 300px;
    max-height: 130px;
  }

  @media (max-width: 768px) {
    order: 1;
    margin-bottom: 75px;
  }
`;

const ThankYouTitle = styled.h2`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 50px;
  letter-spacing: -1px;
  color: #00a5c8;

  @media (max-width: 992px) {
    font-size: 36px;
    line-height: 50px;
  }

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 40px;
  }

  @media (max-width: 576px) {
    font-size: 24px;
    line-height: 30px;
  }
`;

const Text = styled.p`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  color: #202323;

  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 32px;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }

  @media (max-width: 576px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const StyledFlex = styled(Flex)`
  @media (max-width: 768px) {
    flex-direction: column;
    ${StyledThumbnail} {
      max-width: 300px;
    }
  }

  @media (max-width: 992px) {
    ${StyledThumbnail} {
      max-width: 400px;
    }
  }

  @media (max-width: 576px) {
    ${StyledThumbnail} {
      max-width: 100%;
    }
  }
`;

const Subtitle = styled.h4`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #202323;

  @media (max-width: 992px) {
    font-size: 18px;
    line-height: 24px;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 20px;
  }

  @media (max-width: 576px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const GatedContentForm = ({ thumbnailSrc, thumbnailAltText, videoSrc, webinarTitle }) => {
  const HSFormsScriptLoaded = useHSFormsScriptLoaded();
  const [submitted, setSubmitted] = useState();
  useEffect(() => {
    if (HSFormsScriptLoaded) {
      window.hbspt.forms.create({
        region: 'na1',
        portalId: '14573977',
        formId: '2a45350b-0c20-48aa-badc-2004e86a2ba7',
        target: '#webinar-page-form',
        onFormSubmit: () => {
          localStorage.setItem('webinnarFormSubmitted', true);
          setSubmitted(true);
        },
      });
    }
  }, [HSFormsScriptLoaded]);

  useEffect(() => {
    setSubmitted(!!localStorage.getItem('webinnarFormSubmitted'));
  }, []);

  return (
    <Box>
      {!submitted ? <Title>Get full access to "{webinarTitle}"</Title> : <Title>View {webinarTitle}</Title>}
      <Subtitle>
        Discover valuable insights and stay ahead of the curve in the ever-evolving world of technology and talent.
      </Subtitle>
      <StyledFlex justifyContent='center' alignItems='center' pt='30px' pb='75px'>
        {!submitted ? (
          <>
            <StyledFlex justifyContent='start' alignItems='start'>
              <StyledThumbnail src={thumbnailSrc} alt={thumbnailAltText} />
              <WebinarPageForm id='webinar-page-form' className='nexton-form' />
            </StyledFlex>
          </>
        ) : (
          <>
            <StyledVideo title='vimeo-player' src={videoSrc} allowFullScreen frameBorder='0' />
            <ThankYouText>
              <ThankYouTitle>Thank you!</ThankYouTitle>
              <Text>
                We're super excited that you've joined our exclusive tech and talent webinars presented by Nexton
                experts and our partners. Your presence really means a lot to us, and we hope you enjoy learning some
                awesome and valuable stuff in the session.
              </Text>
            </ThankYouText>
          </>
        )}
      </StyledFlex>
    </Box>
  );
};

export default GatedContentForm;
