import React from 'react';
import { Link as GatsbyLink, graphql } from 'gatsby';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import GatedContentForm from '../../../components/resources/gated-content-form';
import Layout from '../../../components/layout/Layout';
import MetaData from '../../../components/MetaData';
import WebinarCard from '../../../components/resources/WebinarCard';

const TextContent = styled.div`
  max-height: 100%;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  color: #202323;
  letter-spacing: -0.5px;

  h1,
  h2,
  h3,
  h4 {
    font-size: 42px;
    line-height: 60px;
    letter-spacing: -1px;
    margin-bottom: 20px;
  }

  p {
    font-size: 22px;
    line-height: 40px;
  }

  p,
  h1,
  h2,
  h3,
  h4 {
    strong {
      font-weight: 700; // adjust this value as per your requirement
    }
  }

  @media (max-width: 1270px) {
    h1,
    h2,
    h3,
    h4 {
      margin-bottom: 14px;
    }
  }

  @media (max-width: 1024px) {
    h1,
    h2,
    h3,
    h4 {
      margin-top: 8px;
    }
  }

  @media (max-width: 1050px) {
    overflow: hidden;
  }

  @media (max-width: 920px) {
    h1,
    h2,
    h3,
    h4 {
      font-size: 28px;
      margin-bottom: 24px;
    }

    p {
      font-size: 18px;
    }
  }
`;

const WebinarListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 45px;
  gap: 48px;
  width: fit-content;
  height: 1158px;
  left: calc(50% - 1366px / 2);
  top: 1570px;
  background: #ebf8fb;
  box-shadow: 0px 41px 60px 11px rgba(161, 161, 161, 0.160047);
  border-radius: 20px;
  margin-top: 50px;
  height: fit-content;
  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 50px;
  }
`;

const AllWebinarsButton = styled.button`
  margin-top: 30px;
  width: fit-content;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  margin-top: 72px;
  gap: 8px;
  background: #ff6d00;
  border: 2px solid #ffffff;
  border-radius: 100px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const WebinarTemplate = ({ data }) => {
  const webinar = data?.sanityWebinar;
  const webinars = data?.allSanityWebinar;

  return (
    <>
      <MetaData title={webinar?.title} description={webinar?.metaDescription} image={webinar?.shareImage?.asset?.url} />

      <Layout selected='resources'>
        <GatedContentForm
          webinarTitle={webinar?.title}
          thumbnailSrc={webinar?.landingImage?.asset.url.images.fallback.src}
          thumbnailAltText={webinar?.landingImageAlt}
          videoSrc={webinar?.video}
        />
        <TextContent>
          <PortableText value={webinar?.description} />
        </TextContent>
        <WebinarListContainer>
          <WebinarCard
            webinarProp={
              webinars?.nodes?.[0]?.slug?.current === webinar?.slug?.current ? webinars?.nodes[1] : webinars?.nodes[0]
            }
          />
        </WebinarListContainer>
        <GatsbyLink to='/resources/webinars'>
          <ButtonContainer>
            <AllWebinarsButton>
              All Webinars <FontAwesomeIcon icon={faArrowRight}> </FontAwesomeIcon>
            </AllWebinarsButton>
          </ButtonContainer>
        </GatsbyLink>
      </Layout>
    </>
  );
};

export default WebinarTemplate;

export const query = graphql`
  query SanityWebinar($slug__current: String!) {
    sanityWebinar(slug: { current: { eq: $slug__current } }) {
      title
      slug {
        current
      }
      metaDescription
      shareImage {
        asset {
          url
        }
      }
      description {
        _rawChildren
        _key
        _type
        children {
          marks
          text
          _type
          _key
        }
        style
        list
      }
      thumbnail {
        asset {
          url: gatsbyImageData(
            width: 614
            height: 321
            placeholder: NONE
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      thumbnailAlt
      landingImage {
        asset {
          url: gatsbyImageData(
            width: 614
            height: 321
            placeholder: NONE
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      landingImageAlt
      excerpt {
        _rawChildren(resolveReferences: { maxDepth: 4 })
        _key
        list
        style
        children {
          _key
          marks
          _type
          text
        }
        _type
      }
      _createdAt
      video
    }
    allSanityWebinar(sort: { fields: _createdAt, order: DESC }) {
      nodes {
        title
        slug {
          current
        }
        description {
          _rawChildren
          _key
          _type
          children {
            marks
            text
            _type
            _key
          }
          style
          list
        }
        thumbnail {
          asset {
            url: gatsbyImageData(
              width: 614
              height: 321
              placeholder: NONE
              layout: CONSTRAINED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
        thumbnailAlt
        excerpt {
          _rawChildren(resolveReferences: { maxDepth: 4 })
          _key
          list
          style
          children {
            _key
            marks
            _type
            text
          }
          _type
        }
        _createdAt
        video
      }
    }
  }
`;
