import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PortableText } from '@portabletext/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';

const WebinarContainer = styled.div`
  padding: ${props => (props.separator ? '0px 0px 72px' : '0px 0px 0px')};
  border-bottom: ${props => (props.separator ? '1px solid #D0D0D0' : 'none')};
  width: 100%;

  @media (max-width: 1200px) {
    padding: ${props => (props.separator ? '20px 0px 0px 0px;' : '0px 0px 0px')};
  }
`;

const FeaturedTitle = styled.h2`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 60px;
  line-height: 68px;
  text-align: center;
  letter-spacing: -1px;
  color: #ff6d00;
  margin-top: 132px;

  @media (max-width: 520px) {
    font-size: 42px;
    line-height: 60px;
    margin-top: 80px;
  }
`;

const FeaturedSubtitle = styled.h4`
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  text-align: center;
  letter-spacing: -0.5px;
  color: #202323;

  @media (max-width: 520px) {
    margin-bottom: 10px;
  }
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')};
  width: 100%;

  @media (max-width: 1194px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StyledThumbnail = styled.img`
  max-width: 614px;
  max-height: 464px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 40px;
  border-radius: 10px;

  @media (max-width: 992px) {
    max-width: 400px;
    max-height: 300px;
  }

  @media (max-width: 580px) {
    max-width: 260px;
    max-height: 200px;
    margin-right: 0px;
  }
`;

const ExcerptContainer = styled.div`
  width: 50%;
  padding-top: 30px;
  margin-right: 30px;

  @media (max-width: 1194px) {
    width: 100%;
    align-self: center;
    padding: 40px 80px;
  }

  @media (max-width: 830px) {
    width: 100%;
    margin: 30px;
    padding: 40px 40px;
  }

  @media (max-width: 580px) {
    width: 100%;
    margin: 10px;
    padding: 0px;
  }
`;
const WebinarExcerpt = styled.div`
  margin-left: 30px;
  margin-right: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 0, 5px;

  @media (max-width: 580px) {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0, 5px;
    color: #202323;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const ViewButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  gap: 8px;
  width: 140px;
  height: 58px;
  background: linear-gradient(90deg, #00a5c8 0%, #01c8ef 100%);
  border-radius: 100px;
  border: 0px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 40px;
  cursor: pointer;
`;

const QuoteContainer = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  transform: ${props => (props.reverse ? 'rotate(180deg)' : 'rotate(0deg)')};
  margin-right: 1rem;
`;

const CommonTitle = styled.h2`
  margin-top: 10px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 60px;
  letter-spacing: -1px;
  color: #202323;
  margin-bottom: 10px;

  @media (max-width: 550px) {
    margin-left: 0px;
    font-size: 28px;
    line-height: 38px;
    letter-spacing: -0.5px;
    text-align: center;
  }

  @media (max-width: 400px) {
    font-size: 25px;
  }
`;

const CommonSubtitle = styled.h4`
  display: flex;
  margin-top: 10px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: -0.5px;
  color: #202323;

  @media (max-width: 550px) {
    margin-left: 0px;
  }
`;

const Pill = styled.div`
  color: ${({ theme, color }) => theme.colors[color] || color || '#3B4B53'};
  background-color: ${({ theme, color }) => theme.colors[color] || color};
  border-radius: 24px;
  font-weight: 500;
  font-size: 16px;
  margin: ${({ color }) => (color ? '1px' : '1px')} 0px;
  padding: 5px 10px;
  max-width: fit-content;
  margin-bottom: ${props => props.featured && !props.mobile && '40px'};
  @media (max-width: 640px) {
    font-size: 14px;
  }
`;

const WebinarBody = ({ webinarProp, reverse, pill = false }) => {
  const [windowWidth, setWindowWidth] = useState();

  const { file } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "images/resources/quote1.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 95, placeholder: NONE, layout: CONSTRAINED)
        }
        name
      }
    }
  `);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <BodyContainer reverse={reverse}>
      <StyledThumbnail src={webinarProp?.thumbnail?.asset.url.images.fallback.src} alt={webinarProp?.thumbnailAlt} />
      <ExcerptContainer>
        {pill && (
          <Pill color='secondary' style={{ backgroundColor: 'white' }} featured mobile={windowWidth <= 520}>
            Webinar
          </Pill>
        )}
        <QuoteContainer reverse={false} hidden={windowWidth <= 520}>
          <GatsbyImage image={file.childImageSharp.gatsbyImageData} alt='Hero HomePage Image' />
        </QuoteContainer>
        <WebinarExcerpt>
          <PortableText value={webinarProp?.excerpt} />
        </WebinarExcerpt>
        <QuoteContainer reverse hidden={windowWidth < 520}>
          <GatsbyImage image={file.childImageSharp.gatsbyImageData} alt='Hero HomePage Image' />
        </QuoteContainer>
        <GatsbyLink to={`/resources/webinar/${webinarProp?.slug.current}`}>
          <ViewButton>View</ViewButton>
        </GatsbyLink>
      </ExcerptContainer>
    </BodyContainer>
  );
};

const WebinarCard = ({ webinarProp, separator, featured, reverse }) => {
  return (
    <div>
      {featured ? (
        <WebinarContainer separator={separator}>
          <FeaturedTitle>{webinarProp?.title}</FeaturedTitle>
          <FeaturedSubtitle>{webinarProp?.subtitle}</FeaturedSubtitle>
          <WebinarBody webinarProp={webinarProp} reverse={false} pill />
        </WebinarContainer>
      ) : (
        <WebinarContainer separator={separator}>
          <CommonTitle>{webinarProp?.title}</CommonTitle>
          <Pill color='secondary' style={{ backgroundColor: 'white' }}>
            Webinar
          </Pill>
          <CommonSubtitle style={{ marginTop: '33px' }}>{webinarProp?.subtitle}</CommonSubtitle>
          <WebinarBody webinarProp={webinarProp} reverse={reverse} />
        </WebinarContainer>
      )}
    </div>
  );
};

export default WebinarCard;
